import { Component, OnInit, Input } from '@angular/core';
import { ServerComunicationService } from '../services/server-comunication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductSvcService } from '../services/product-svc.service';

@Component({
  selector: 'app-delete-pop-up',
  templateUrl: './delete-pop-up.component.html',
  styleUrls: ['./delete-pop-up.component.css']
})
export class DeletePopUpComponent implements OnInit {

  @Input()id: number;

  constructor(private serverSvc:ServerComunicationService, public activeModal: NgbActiveModal, public productSvc:ProductSvcService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  async deleteProduct(){
    await this.serverSvc.deleteProduct(this.id)
    await this.productSvc.updateProducts(this.productSvc.currentPage)
    this.activeModal.close('Modal Closed');
  }
}
