<img src="../../assets/Logo.jpg" class="logo" style="padding: none;">
<div class="header-component">
    <h1 routerLink="/" routerLinkActive="active" style="cursor: pointer;">Toate produsele</h1>
    <fa-icon [icon]="faPlusCircle" class="plusIcon" (click)="openFormModal()"></fa-icon>
</div>
<div class="header-searchbox">
    <div class="search-wrapper" > 
        <input [(ngModel)]="this.searchWord" class="searchbar" type="text">
       <fa-icon [icon]="faSearch"  (click)="performSearch()" style="cursor: pointer;" class="icon-search"></fa-icon>
    </div>
</div>