import { Component, OnInit } from '@angular/core';
import { Product } from '../Models/Product';
import { ServerComunicationService } from '../services/server-comunication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {  faTimes}  from '@fortawesome/free-solid-svg-icons';
import { ProductSvcService } from '../services/product-svc.service';
import { ImageSnippet } from '../helpers/ImageSnippet';



@Component({
  selector: 'app-insert-product',
  templateUrl: './insert-product.component.html',
  styleUrls: ['./insert-product.component.css']
})

export class InsertProductComponent implements OnInit {

  productToBeInserted: Product

  selectedImage:File=null;

  file=null

  constructor(private serverSvc:ServerComunicationService, public activeModal: NgbActiveModal,public productSvc:ProductSvcService) {
    this.productToBeInserted=new Product("",this.file,"","","","")
  }

  ngOnInit(): void {
  }

  async onAdd(){
    this.productToBeInserted.image=this.file
    if(this.productToBeInserted.name!="" && this.productToBeInserted.price>0){
      await this.serverSvc.addProduct(this.productToBeInserted)
    }
    this.productToBeInserted=new Product("","","","","","")
    await this.productSvc.updateProducts(this.productSvc.currentPage)
    this.closeModal();
    
  }


  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  onFileSelected(event){
  this.selectedImage=event.target.files[0];
  var selectedFile;
  const reader = new FileReader();
  reader.addEventListener('load', (event: any) => {
    selectedFile = new ImageSnippet(event.target.result, this.selectedImage);
    this.file=selectedFile.src;
    console.log( this.file)
  });

  reader.readAsDataURL(this.selectedImage);
  }
}
