import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductListComponent } from './product-list/product-list.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { SearchComponent } from './search/search.component';


const routes: Routes = [{ path: '', component: ProductListComponent, canActivate: [AuthGuard] },
{ path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
{ path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
