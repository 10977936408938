import { Component, OnInit } from '@angular/core';
import { ServerComunicationService } from '../services/server-comunication.service';
import { ProductSvcService } from '../services/product-svc.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsertProductComponent } from '../insert-product/insert-product.component';
import {  faPlusCircle, faSearch}  from '@fortawesome/free-solid-svg-icons';
import { DeletePopUpComponent } from '../delete-pop-up/delete-pop-up.component';
import { EditPopUpComponent } from '../edit-pop-up/edit-pop-up.component';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  faPlusCircle=faPlusCircle
  faSearch=faSearch

  searchWord: string



  constructor(public searchSvc:SearchService,public productSvc: ProductSvcService,private modalService: NgbModal,public router:Router) { }

  ngOnInit(): void {
    this.productSvc.updateProducts();
  }


  openDeleteModal(id) {
    const modalRef = this.modalService.open(DeletePopUpComponent);
    modalRef.componentInstance.id = id; 
    modalRef.result.then((result) => {
      this.productSvc.updateProducts()
    }).catch((error) => {
      console.log(error);
    });
  }

  openEditModal(product) {
    const modalRef = this.modalService.open(EditPopUpComponent);
    modalRef.componentInstance.ProductToBeEdited = product; 
    modalRef.result.then((result) => {
      this.productSvc.updateProducts()
    }).catch((error) => {
      console.log(error);
    });
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  pageChanged(i){
    this.productSvc.updateProducts(i)
  }
  nextPage(){
    this.productSvc.updateProducts(Number(this.productSvc.currentPage)+1)
  }



}
