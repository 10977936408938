export class Product
{
    public _id; 
    public image:string;
    public name:string;
    public price:number;
    public description:string;
    public quantity:number; 
    public category:number; 

    constructor(Id,Image,Name,Description,Quantity,Category){
        this.image=Image;
        this._id=Id;
        this.name=Name;
        this.description=Description;
        this.quantity=Quantity;
        this.category=Category;
    }

}