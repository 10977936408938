import { Injectable } from '@angular/core';
import { Product } from '../Models/Product';
import { ServerComunicationService } from './server-comunication.service';

@Injectable({
  providedIn: 'root'
})
export class ProductSvcService {

  currentPage:number

  numberOfPages:number
   
  productsToBeDisplayed: Product[]=[]

  selectedProduct: Product

  constructor(private serverSvc: ServerComunicationService) { }

  async updateProducts(pageToBeChanged=1){
    var page=await this.serverSvc.getProducts(pageToBeChanged);
    this.productsToBeDisplayed=page.products;
    this.currentPage=page.currentPage;
    this.numberOfPages=page.totalPages;
  }

  async updateProductsAfterSearch(word,pageToBeChanged=1){
    var page=await this.serverSvc.getProductsAfterSearch(word,pageToBeChanged);
    this.productsToBeDisplayed = page.products;
    this.currentPage=page.currentPage;
    this.numberOfPages=page.totalPages;
  }
}
