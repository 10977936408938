import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Product } from '../Models/Product';
import { environment } from 'src/environments/environment';
import { Page } from '../helpers/page';

@Injectable({
  providedIn: 'root'
})
export class ServerComunicationService {

  constructor(private http: HttpClient) { }

  getProducts(page=1){
    return this.http.get<Page>(environment.apiEndpoint+'/products'+'?page='+page).toPromise()
  }

  async deleteProduct(id){
    await this.http.delete(environment.apiEndpoint+'/products/'+id).toPromise()
  }

  async addProduct(prod: Product) {
    var header = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    const body = JSON.stringify(prod);
    await this.http.post(`${environment.apiEndpoint}/products`, body, { headers: header }).toPromise();
  }

   async updateProduct(prod: Product) {
    var header = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    const body = JSON.stringify(prod);
    await this.http.put(`${environment.apiEndpoint}/products/`+prod._id, body, { headers: header }).toPromise();
  }

  getProductsAfterSearch(searchWord,page=1){
    return this.http.get<Page>(environment.apiEndpoint+'/products/search'+'?page='+page+'&word='+searchWord).toPromise()
  }
}
