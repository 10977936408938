import { Component, OnInit, Input } from '@angular/core';
import { ServerComunicationService } from '../services/server-comunication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductSvcService } from '../services/product-svc.service';
import { Product } from '../Models/Product';
import { ImageSnippet } from '../helpers/ImageSnippet';

@Component({
  selector: 'app-edit-pop-up',
  templateUrl: './edit-pop-up.component.html',
  styleUrls: ['./edit-pop-up.component.css']
})
export class EditPopUpComponent implements OnInit {

  @Input() ProductToBeEdited:Product

  constructor(public serverSvc:ServerComunicationService, public activeModal: NgbActiveModal,public productSvc:ProductSvcService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  async onEdit(){
   await this.serverSvc.updateProduct(this.ProductToBeEdited)
   await this.productSvc.updateProducts(this.productSvc.currentPage)
    this.closeModal();
  }

  removeImage(){
    this.ProductToBeEdited.image=null;
  }

  onFileSelected(event){
    var selectedImage=event.target.files[0];
    var selectedFile;
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      selectedFile = new ImageSnippet(event.target.result, selectedImage);
      this.ProductToBeEdited.image=selectedFile.src;

    });
  
    reader.readAsDataURL(selectedImage);
    }
}
