<div class="body-container">
    <div class="header">
        <h3>Adauga produs</h3>
    </div>
    <form>
        <div class="input-container">
            <label for="image">Imagine produs:</label>
            <input name="image" type="file" (change)="onFileSelected($event)">
        </div>
        <div class="input-container">
            <label for="name">Nume produs:</label>
            <input name="name" [(ngModel)]="productToBeInserted.name" type="text">
        </div>
        <div class="input-container">
            <label for="price">Pret produs:</label>
            <input name="price" [(ngModel)]="productToBeInserted.price" type="number">
        </div>
        <div class="input-container">
            <label for="quantity">Cantitate disponibila:</label>
            <input name="quantity" [(ngModel)]="productToBeInserted.quantity" type="number">
        </div>
        <div class="input-container">
            <label for="description">Descriere produs:</label>
            <textarea name="description" [(ngModel)]="productToBeInserted.description"></textarea>
        </div>
        <div class="input-container">
            <label for="category">Categorie produs:</label>
            <input name="category" [(ngModel)]="productToBeInserted.category" type="number">
        </div>
    </form>
    <div class="buttons-container">
        <button (click)="onAdd()" class="detailsButton">Adauga</button>
        <button (click)="closeModal()" class="deleteButton">Inchide</button>
    </div>
</div>