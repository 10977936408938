import { Component, OnInit } from '@angular/core';
import {  faPlusCircle, faSearch}  from '@fortawesome/free-solid-svg-icons';
import { SearchService } from '../search.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductSvcService } from '../services/product-svc.service';
import { InsertProductComponent } from '../insert-product/insert-product.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  faPlusCircle=faPlusCircle
  faSearch=faSearch
  searchWord: string

  constructor(public searchSvc:SearchService,public productSvc: ProductSvcService,private modalService: NgbModal,public router:Router) { }

  ngOnInit(): void {
  }

  async performSearch(){
    await this.searchSvc.performSearch(this.searchWord)
    this.searchWord=""
    this.router.navigate(['/search']);
  }

  openFormModal() {
    const modalRef = this.modalService.open(InsertProductComponent);
    modalRef.componentInstance.id = 10; 
    modalRef.result.then((result) => {
      this.productSvc.updateProducts()
    }).catch((error) => {
      console.log(error);
    });
  }

}
