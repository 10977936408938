import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import decode from 'jwt-decode';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private jwtHelper:JwtHelperService) { }

  login(username: string, password: string): Observable<boolean> {
    var header = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    var body=JSON.stringify({username:username,password:password})
    return this.http.post<{token: string}>(`${environment.apiEndpoint}/auth`, body, { headers: header })
      .pipe(
        map(result => {
          localStorage.setItem('access_token', result.token);
          return true;
        })
      );
  }

  logout() {
    localStorage.removeItem('access_token');
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }
  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    return !this.jwtHelper.isTokenExpired(token);
  }

}
