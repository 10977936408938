import { Component, OnInit } from '@angular/core';
import { ProductSvcService } from '../services/product-svc.service';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';
import { DeletePopUpComponent } from '../delete-pop-up/delete-pop-up.component';
import { EditPopUpComponent } from '../edit-pop-up/edit-pop-up.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  constructor(public productSvc: ProductSvcService,private modalService: NgbModal,public searchSvc:SearchService,private router: Router) { }

  ngOnInit(): void {
    if(this.productSvc.productsToBeDisplayed.length==0){
      var word=localStorage.getItem('searchWord')
      this.productSvc.updateProductsAfterSearch(word)
    }
  }


  openDeleteModal(id) {
    const modalRef = this.modalService.open(DeletePopUpComponent);
    modalRef.componentInstance.id = id; 
    modalRef.result.then((result) => {
      this.productSvc.updateProducts()
    }).catch((error) => {
      console.log(error);
    });
  }

  openEditModal(product) {
    const modalRef = this.modalService.open(EditPopUpComponent);
    modalRef.componentInstance.ProductToBeEdited = product; 
    modalRef.result.then((result) => {
      this.productSvc.updateProducts()
    }).catch((error) => {
      console.log(error);
    });
  }



  arrayOne(n: number): any[] {
    return Array(n);
  }

  pageChanged(i){
    this.productSvc.updateProductsAfterSearch(this.searchSvc.searchWord,i)
  }
  nextPage(){
    this.productSvc.updateProductsAfterSearch(this.searchSvc.searchWord,Number(this.productSvc.currentPage)+1)
  }
}
