<div class="body-container">
    <div class="header">
        <h3>Editeaza produsul</h3>
    </div>
    <form>
        <div *ngIf='ProductToBeEdited.image!=null' class="input-container">
            <img src="{{ProductToBeEdited.image}}" id="editImage">
            <button (click)="removeImage()" class="deleteButton">Sterge Imaginea</button>
        </div>
        <div *ngIf="ProductToBeEdited.image==null" class="input-container">
            <label for="image">Imagine produs:</label>
            <input name="image" type="file" (change)="onFileSelected($event)">
        </div>
        <div class="input-container">
            <label for="name">Nume produs:</label>
            <input name="name" [(ngModel)]="ProductToBeEdited.name" type="text">
        </div>
        <div class="input-container">
            <label for="price">Pret produs:</label>
            <input name="price" [(ngModel)]="ProductToBeEdited.price" type="number">
        </div>
        <div class="input-container">
            <label for="quantity">Cantitate disponibila:</label>
            <input name="quantity" [(ngModel)]="ProductToBeEdited.quantity" type="number">
        </div>
        <div class="input-container">
            <label for="description">Descriere produs:</label>
            <textarea name="description" [(ngModel)]="ProductToBeEdited.description"></textarea>
        </div>
        <div class="input-container">
            <label for="category">Categorie produs:</label>
            <input name="category" [(ngModel)]="ProductToBeEdited.category" type="number">
        </div>
    </form>
    <div class="buttons-container">
        <button (click)="onEdit()" class="detailsButton">Editeaza</button>
        <button (click)="closeModal()" class="deleteButton">Inchide</button>
    </div>
</div>